import { transformExtent, transform } from "ol/proj"
import { containsExtent, intersects, getCenter, extend, createEmpty } from "ol/extent"
import { Point } from "ol/geom"

import * as turf from "@turf/turf"

const R = 6373e3 // metres

export const geoExtentToWebMatrix = (extent) => transformExtent(extent, "EPSG:4326", "EPSG:3857")

export const geoCoordsToWebMatrix = (arr) => transform([parseFloat(arr[0]), parseFloat(arr[1])], "EPSG:4326", "EPSG:3857")

export const webMatrixCoordsToGeo = (arr) => transform([parseFloat(arr[0]), parseFloat(arr[1])], "EPSG:3857", "EPSG:4326")

export const webMatrixExtentToGeo = (extent) => transformExtent(extent, "EPSG:3857", "EPSG:4326")

export const decimalToDegreesAbsoluteValues = (value, roundSeconds) => {
  value = Math.abs(value)
  const degrees = Math.trunc(value)
  const minutes = Math.round(Math.trunc((value * 60) % 60))
  let seconds = Number((value * 3600) % 60).toFixed(2)

  if (roundSeconds) {
    seconds = Math.floor(seconds)
  }

  return {
    degrees,
    minutes,
    seconds
  }
}

export const decimalToDegreesAbsolute = (value, roundSeconds) => {
  const data = decimalToDegreesAbsoluteValues(value, roundSeconds)
  return `${data.degrees}° ${data.minutes}' ${data.seconds}''`
}

export const decimalToDegreesLongitude = (value, roundSeconds) => `${decimalToDegreesAbsolute(value, roundSeconds)} ${value >= 0 ? "E" : "W"}`

export const decimalToDegreesLatitude = (value, roundSeconds) => `${decimalToDegreesAbsolute(value, roundSeconds)} ${value >= 0 ? "N" : "S"}`

export const decimalToDegrees = (longitude, latitude) => `${decimalToDegreesLongitude(longitude)}, ${decimalToDegreesLatitude(latitude)}`

export const degreesToDecimal = (degrees, minutes, seconds, direction) => direction * degrees + (direction * minutes) / 60 + (direction * seconds) / 3600

export const convertCoordinatesToDecimal = (value, isLatitude) => {
  const coordsInfo = {
    type: isLatitude ? "latitude" : "longitude",
    positive: isLatitude ? "N" : "E",
    negative: isLatitude ? "S" : "W"
  }

  value = value.trim().replace(/\s+/g, " ")

  if (!isNaN(value)) {
    return parseFloat(value)
  }

  // Support for digits with spaces
  const coordsOnlyRegex = /^(\d+\s+\d+\s+\d+\.?\d*)/
  const directionRegex = new RegExp(
    `\\s+(${coordsInfo.positive}|${coordsInfo.negative}|${coordsInfo.positive.toLowerCase()}|${coordsInfo.negative.toLowerCase()})$`
  )
  const coordsWithSpacesRegex = new RegExp(coordsOnlyRegex.source + directionRegex.source)

  if (coordsOnlyRegex.test(value) && !coordsWithSpacesRegex.test(value)) {
    value += ` ${coordsInfo.positive}`
  }

  if (coordsWithSpacesRegex.test(value)) {
    const matchedCords = value.match(coordsOnlyRegex)[0].replace(/  +/g, " ")
    const matchedDir = value.match(directionRegex)[0].trim()
    const splittedCords = matchedCords.split(" ")

    const degrees = parseInt(splittedCords[0], 10)
    const minutes = parseInt(splittedCords[1], 10)
    const seconds = parseFloat(splittedCords[2])
    const direction = matchedDir.toUpperCase()
    const multiplier = (isLatitude && direction === coordsInfo.negative) || (!isLatitude && direction === coordsInfo.negative) ? -1 : 1

    return degreesToDecimal(degrees, minutes, seconds, multiplier)
  }

  const coordsRegex = /^(\d+)°\s*(\d+)'?\s*(\d+\.\d+|\d+)''?\s*([NSEWnsew]?)$/
  const match = value.match(coordsRegex)

  // Support for coordinates with degrees, minutes and seconds
  if (match) {
    const degrees = parseInt(match[1], 10)
    const minutes = parseInt(match[2], 10)
    const seconds = parseFloat(match[3])
    const direction = match[4] ? match[4].toUpperCase() : null
    const multiplier = (isLatitude && direction === coordsInfo.negative) || (!isLatitude && direction === coordsInfo.negative) ? -1 : 1
    return degreesToDecimal(degrees, minutes, seconds, multiplier)
  }

  return null
}

export const formatCoordinates = (value, isLatitude) => {
  return isLatitude ? decimalToDegreesLatitude(value) : decimalToDegreesLongitude(value)
}

export const isPointInsideExtent = (point, extent) => containsExtent(extent, Point(point).getExtent())

export const doExtentsOverlap = (extent1, extent2) => intersects(extent1, extent2)

export const isPointInsideCircle = (lat1, lat2, lon1, lon2, radius) => calculateDistance(lat1, lat2, lon1, lon2) <= radius

export const getCenterOfExtent = (extent) => getCenter(extent)

export const calculateDistance = (lat1, lat2, lon1, lon2) => {
  const r1 = degToRad(lat1)
  const r2 = degToRad(lat2)
  const rd1 = degToRad(lat2 - lat1)
  const rd2 = degToRad(lon2 - lon1)

  const a = Math.sin(rd1 / 2) * Math.sin(rd1 / 2) + Math.cos(r1) * Math.cos(r2) * Math.sin(rd2 / 2) * Math.sin(rd2 / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c
}

export const degToRad = (deg) => deg * (Math.PI / 180)

export const convertCoordsToPoints = (coords) =>
  coords.reduce((points, coord) => {
    if (coord.lon === undefined || coord.lat === undefined) {
      return points
    }
    const pointsInWebMatrix = geoCoordsToWebMatrix([coord.lon, coord.lat])
    points.push(pointsInWebMatrix)
    return points
  }, [])

export const getSingleExtent = (extents, geoLocalized) => {
  let singleExtent = null
  const defaultExtents = extents.find((e) => e.default)
  if (extents.length === 1) {
    singleExtent = geoExtentToWebMatrix(extents[0])
  } else if (defaultExtents && geoLocalized) {
    const defaultExtent = geoExtentToWebMatrix(defaultExtents)
    const centerPoint = getCenterOfExtent(defaultExtent)
    if (isPointInsideExtent(centerPoint, defaultExtent)) {
      singleExtent = defaultExtent
    }
  }
  if (!singleExtent) {
    singleExtent = createEmpty()
    extents.forEach((e) => extend(singleExtent, geoExtentToWebMatrix(e)))
  }
  return singleExtent
}

export const moveCoordinates = (lon, lat, dx, dy) => {
  const newLatitude = Number(lat) + (dy / R) * (180 / Math.PI)
  const newLongitude = Number(lon) + ((dx / R) * (180 / Math.PI)) / Math.cos((lat * Math.PI) / 180)
  return [newLongitude, newLatitude]
}

export const parseGeoJsonObj = (obj) => {
  const ret = []
  if (obj.type === "FeatureCollection") {
    obj.features.forEach((feature) => {
      if (feature.geometry) {
        ret.push(parseFeature(feature.geometry))
      }
    })
  } else if (obj.geometry) {
    ret.push(parseFeature(obj.geometry))
  } else if (obj.type) {
    ret.push(parseFeature(obj))
  }
  return ret.filter((multiPolygon) => multiPolygon.polygons.length)
}

const parseFeature = (geometry) => {
  const multiPolygon = {
    polygons: []
  }

  if (geometry.type === "Polygon") {
    multiPolygon.polygons.push(parsePolygon(geometry.coordinates))
  }
  if (geometry.type === "MultiPolygon") {
    geometry.coordinates.forEach((polygonCoords) => {
      multiPolygon.polygons.push(parsePolygon(polygonCoords))
    })
  }
  return multiPolygon
}

const parsePolygon = (coordinates) => {
  const ensureOpenRing = (ring) => {
    if (ring.length > 0 && ring[0][0] === ring[ring.length - 1][0] && ring[0][1] === ring[ring.length - 1][1]) {
      return ring.slice(0, -1) // Remove the last point if it's the same as the first
    }
    return ring
  }

  return {
    coords: ensureOpenRing(coordinates[0]),
    holes: coordinates.slice(1).map(ensureOpenRing)
  }
}

export const getClosedRing = (coords) => {
  if (coords.length === 0) {
    return []
  }

  const first = coords[0]
  const last = coords[coords.length - 1]

  if (first[0] === last[0] && first[1] === last[1]) {
    return coords.slice()
  } else {
    return coords.concat([first])
  }
}

export const getCirclePolygon = (longitude, latitude, radius) => {
  const circlePolygon = turf.circle([longitude, latitude], radius, { units: "kilometers" })

  return {
    coords: circlePolygon.geometry.coordinates[0],
    holes: []
  }
}

export const getRectanglePolygon = (longitude, latitude, width, height, angle) => {
  const bbox = [longitude - width / 2 / 111.32, latitude - height / 2 / 111.32, longitude + width / 2 / 111.32, latitude + height / 2 / 111.32]

  const rectangle = turf.bboxPolygon(getClosedRing(bbox))
  const centroid = turf.centroid(rectangle)
  const rotatedRectangle = turf.transformRotate(rectangle, angle || 0, { pivot: centroid.geometry.coordinates })

  const coords = rotatedRectangle.geometry.coordinates[0]
  coords.pop()

  return {
    coords,
    holes: []
  }
}

const simplifyPolygonCoords = (coordinates, maxCoords) => {
  const targetCount = maxCoords
  const result = []
  const originalCount = coordinates.length

  if (originalCount <= targetCount) {
    return coordinates
  }

  const removalRate = originalCount / targetCount

  for (let i = 0, idx = 0; i < originalCount && result.length < targetCount; i++) {
    if (Math.floor(idx) === i) {
      result.push(coordinates[i])
      idx += removalRate
    }
  }

  if (result[result.length - 1] !== coordinates[originalCount - 1]) {
    if (result.length === targetCount) {
      result.pop()
    }
    result.push(coordinates[originalCount - 1])
  }

  return result
}

const simplifyPolygon = (polygon, maxCoords) => {
  return {
    coords: simplifyPolygonCoords(polygon.coords, maxCoords),
    holes: polygon.holes.map((hole) => simplifyPolygonCoords(hole, maxCoords))
  }
}

export const simplifyMultiPolygon = (multiPolygon, maxCoords) => {
  return {
    polygons: multiPolygon.polygons.map((polygon) => simplifyPolygon(polygon, maxCoords))
  }
}

export const canAddPointToPolygon = (polygon, pt) => {
  const isInsideHole = isPointInsideOfAnyPolygonHole(polygon, pt)
  const hasIntersections = hasPolygonIntersectionsWithAddedPoint(polygon, pt)

  return !isInsideHole && !hasIntersections
}

export const canAddPointToPolygonHole = (polygon, pt, hole) => {
  const isInside = isPointInsidePolygon(polygon, pt, hole)
  const hasIntersections = hasPolygonIntersectionsWithAddedPoint(polygon, pt, hole)

  return isInside && !hasIntersections
}

const isPointInsidePolygon = (polygon, pt, excludingHole) => {
  if (!polygon.coords.length || polygon.coords.length < 3) {
    return false
  }

  const points = polygon.coords.slice()
  points.push(points[0])
  const inputArr = [points]
  polygon.holes.forEach((hole) => {
    if (hole === excludingHole) {
      return
    }
    const holePoints = hole.slice()
    holePoints.push(holePoints[0])
    inputArr.push(holePoints)
  })

  const poly = turf.polygon(inputArr)
  return turf.booleanPointInPolygon(pt, poly)
}

const isPointInsideOfAnyPolygonHole = (polygon, pt) => {
  if (!polygon.holes?.length) {
    return false
  }

  return polygon.holes.some((hole) => isPointInsidePolygon({ coords: hole, holes: [] }, pt, hole))
}

const hasPolygonIntersectionsWithAddedPoint = (polygon, point, hole = null) => {
  const holeIndex = hole === null ? null : polygon.holes.findIndex((h) => h === hole)

  const rings = [polygon.coords.slice(), ...(polygon.holes || []).map((h) => h.slice())]

  const ringToModify = holeIndex === null ? rings[0] : rings[holeIndex + 1]
  ringToModify.push(point)

  const segments = []
  rings.forEach((ring) => {
    const numPoints = ring.length
    for (let i = 0; i < numPoints - 1; i++) {
      segments.push({
        ring,
        index: i,
        points: [ring[i], ring[i + 1]]
      })
    }
  })

  const pointsEqual = (p1, p2) => p1[0] === p2[0] && p1[1] === p2[1]

  for (let i = 0; i < segments.length; i++) {
    const seg1 = segments[i]
    for (let j = i + 1; j < segments.length; j++) {
      const seg2 = segments[j]

      // Skip if segments are adjacent (share an endpoint)
      if (
        pointsEqual(seg1.points[1], seg2.points[0]) ||
        pointsEqual(seg1.points[0], seg2.points[1]) ||
        pointsEqual(seg1.points[0], seg2.points[0]) ||
        pointsEqual(seg1.points[1], seg2.points[1])
      ) {
        continue
      }

      const intersects = turf.lineIntersect(turf.lineString(seg1.points), turf.lineString(seg2.points))
      if (intersects.features.length > 0) {
        return true
      }
    }
  }

  return false
}
