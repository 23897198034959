<template>
  <q-input ref="input" :model-value="internalValue" dense flat outlined hide-bottom-space :rules="computedRules" @update:model-value="onUpdateModelValue">
    <template v-if="unit" #append>
      <div class="text-caption">
        {{ unit }}
      </div>
    </template>
    <template v-for="(slot, name) in $slots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope ?? {}" />
    </template>
    <template v-if="infoButtonId || infoButtonKey" #after>
      <slot name="after" />
      <app-info-button :identifier="infoButtonId" :translation-key="infoButtonKey" />
    </template>
  </q-input>
</template>

<script setup>
import { ref, watch, computed, nextTick } from "vue"

import { useModuleDataStore } from "@/stores/moduleData.js"

import { useValidators } from "@/composables/useValidators"

const validators = useValidators()

const moduleStore = useModuleDataStore()

const props = defineProps({
  modelValue: [String, Number, Array],
  unit: String,
  required: Boolean,
  url: Boolean,
  latin: Boolean,
  email: Boolean,
  date: Boolean,
  rules: Array,
  upperCase: Boolean,
  infoButtonId: String,
  infoButtonKey: String,
  requiredCondition: Boolean,
  requiredConditionKey: String
})

const input = ref(null)
const internalValue = ref(props.modelValue)

const emit = defineEmits(["update:modelValue"])

watch(
  () => props.modelValue,
  (newValue) => {
    internalValue.value = newValue
  },
  { immediate: true }
)

const isRequired = computed(() => {
  if (props.required) {
    return true
  }

  if (props.requiredCondition || props.requiredConditionKey) {
    const conditionKey = props.requiredConditionKey || props.infoButtonKey
    return moduleStore.moduleSettings.requiredFields?.includes(conditionKey)
  }

  return false
})

const computedRules = computed(() => {
  const rules = props.rules ? [...props.rules] : []
  if (isRequired.value) {
    rules.push(validators.required)
  }
  if (props.url) {
    rules.push(validators.url)
  }
  if (props.latin) {
    rules.push(validators.latin)
  }
  if (props.email) {
    rules.push(validators.email)
  }
  if (props.date) {
    rules.push(validators.date)
  }

  return rules
})

const onUpdateModelValue = (value) => {
  if (props.upperCase) {
    value = value.toString().toUpperCase()
  }
  internalValue.value = value
  emit("update:modelValue", value)
}

const arraysAreEqual = (arr1, arr2) => arr1.length === arr2.length && arr1.every((fn, i) => fn.toString() === arr2[i].toString())

// Sometimes reactive-rules prop didn't work as expected,
watch(
  () => computedRules.value,
  async (newValue, oldValue) => {
    //sometimes it get's the same new and old value
    if (arraysAreEqual(newValue, oldValue)) {
      return
    }
    await input.value.resetValidation()
    input.value.validate()
  }
)

defineExpose({
  validate: () => input.value.validate(),
  focus: async () => {
    await nextTick()
    input.value.focus()
  }
})
</script>
