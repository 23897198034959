export const worldLayers = [
  { value: "Road", name: "BING Road" },
  { value: "AerialWithLabels", name: "BING Aerial With Labels" },
  { value: "GoogleStreet", name: "Google Street" },
  { value: "GooglePhysical", name: "Google Physical" },
  { value: "GoogleAerial", name: "Google Aerial" },
  { value: "GoogleAerialLabels", name: "Google Aerial Labels" },
  { value: "OpenStreetMap", name: "Open Street Map" },
  { value: "StamenToner", name: "Stamen Toner" },
  { value: "OpenTopoMap", name: "Open Topo Map" },
  { value: "MapBox", name: "Map Box" },
  { value: "Here", name: "Here" },
  { value: "HereTerrain", name: "Here Terrain" },
  { value: "HereHybrid", name: "Here Hybrid" },
  { value: "HereSat", name: "Here Sat" },
  { value: "Relief", name: "Relief" },
  { value: "WorldOrtho", name: "World Ortho" },
  { value: "WorldTopo", name: "World Topo" },
  { value: "OS_2016", name: "OS_2016" },
  { value: "OpenRailway", name: "OpenRailway" },
  { value: "Oceans", name: "Oceans" },
  { value: "ICAO", name: "ICAO" }
]

export const nationalLayers = [
  { value: "RSA_Ortho", name: "RSA Ortho" },
  { value: "LT_Ortho", name: "LT Ortho" },
  { value: "DK_Ortho", name: "DK Ortho" },
  { value: "NSW_Ortho", name: "NSW Ortho" },
  { value: "AUT_Ortho", name: "AUT Ortho" },
  { value: "BEL_Topo", name: "BEL Topo" },
  { value: "Cat_Topo", name: "Cat Topo" },
  { value: "HRV_Ortho", name: "HRV Ortho" },
  { value: "HRV_Topo", name: "HRV Topo" },
  { value: "HRV_OrthoIns", name: "HRV OrthoIns" },
  { value: "HRV_TKALL", name: "HRV TKALL" },
  { value: "CZ_Topo", name: "CZ Topo" },
  { value: "F_Cadastre", name: "F Cadastre" },
  { value: "F_Ortho", name: "F Ortho" },
  { value: "F_Topo", name: "F Topo" },
  { value: "F_OACI", name: "F OACI" },
  { value: "F_Scan25", name: "F Scan25" },
  { value: "D_Topo", name: "D Topo" },
  { value: "IT_Topo", name: "IT Topo" },
  { value: "LU_Ortho", name: "LU Ortho" },
  { value: "LU_Topo", name: "LU Topo" },
  { value: "LU_Hybrid", name: "LU Hybrid" },
  { value: "NC_Ortho", name: "NC Ortho" },
  { value: "NC_Cartography", name: "NC Cartography" },
  { value: "NC_Imagery", name: "NC Imagery" },
  { value: "NC_Clutter", name: "NC Clutter" },
  { value: "NZ_Lds", name: "NZ Lds" },
  { value: "SKP_Topo", name: "SKP Topo" },
  { value: "NOR_Statkart", name: "NOR Statkart" },
  { value: "PT_Topo", name: "PT Topo" },
  { value: "PL_Ortho", name: "PL Ortho" },
  { value: "PL_Topo", name: "PL Topo" },
  { value: "PL_Kataster", name: "PL Kataster" },
  { value: "PL_InspireKataster", name: "PL InspireKataster" },
  { value: "SP_Topo", name: "SP Topo" },
  { value: "SP_Ortho", name: "SP Ortho" },
  { value: "CH_Ortho", name: "CH Ortho" },
  { value: "CH_Topo", name: "CH Topo" },
  { value: "UKR_RegionsGray", name: "UKR RegionsGray" },
  { value: "US_Topo", name: "US Topo" }
]
