<template>
  <div class="row gap-1">
    <q-icon v-if="show" size="sm" name="info" color="primary">
      <!-- eslint-disable -->
      <q-tooltip class="bg-primary" v-html="value" />
    </q-icon>
    <app-button v-if="relatedAttachmentData" size="sm" icon="attach_file" dense @click="showAttachmentDialog = true" />
    <app-dialog v-if="showAttachmentDialog" :show-cancel="false" min-height="600px" @ok="showAttachmentDialog = false">
      <template #title>
        {{ relatedAttachmentData.attachmentName }}
      </template>
      <template #content>
        <div style="height: 560px">
          <app-file-viewer :file-path="relatedAttachmentData.attachmentName" :file-src="srcForAttachment" />
        </div>
      </template>
    </app-dialog>
  </div>
</template>

<script setup>
import { ref, computed } from "vue"

import { useGlobalStore } from "@/stores/global.js"
import { useModuleDataStore } from "@/stores/moduleData.js"
import { useSessionStore } from "@/stores/session.js"

import i18n from "@/plugins/i18n.js"

import DOMPurify from "dompurify"

import AppFileViewer from "@/components/commons/AppFileViewer.vue"

import { getModuleForRoute } from "@/plugins/router.js"

const globalStore = useGlobalStore()
const moduleStore = useModuleDataStore()

const props = defineProps({
  identifier: String,
  translationKey: String
})

const showAttachmentDialog = ref(false)

const keyForIdentifier = computed(() => props.identifier || props.translationKey)
const keyForTranslation = computed(() => props.translationKey || props.identifier)

const show = computed(
  () => globalStore.globalConfig.settings.informationButtons && moduleStore.moduleSettings.enabledInformationButtons.includes(keyForIdentifier.value)
)

const value = computed(() => DOMPurify.sanitize(i18n.global.t(keyForTranslation.value)))

const relatedAttachmentData = computed(() =>
  moduleStore.moduleSettings.informationButtonsAttachementsMap?.find((item) => item.informationButton === keyForIdentifier.value)
)

const modulePathForInformationButtonAttachment = computed(() => {
  const currentModule = getModuleForRoute()

  if (currentModule === "freqExperimentationRequests") {
    return "/api/freqExperimentationRequests/documentation/getDocument"
  }

  if (currentModule === "comsis") {
    return "/api/comsis/comsisDocumentation/getDocument"
  }
  return null
})

const srcForAttachment = computed(() => {
  if (!relatedAttachmentData.value || !modulePathForInformationButtonAttachment.value) {
    return null
  }

  return `${modulePathForInformationButtonAttachment.value}?name=${relatedAttachmentData.value.attachmentName}&access_token=${useSessionStore().accessToken}`
})
</script>
